// import mobile from './img/mobile.png';
// import desktop from './img/desktop.png';
import nexus from './img/nexus.png';
import healio from './img/healio.png';

export const PROJECT_DETAILS = [
    {
        title: 'Nexus',
        role: 'Frontend Web Developer',
        image:
            {
                src: nexus,
                alt: 'Icon of Nexus'
            },
        startDate: 'June 2022',
        endDate: 'May 2023',
        tools: ['React', 'Redux', 'Netlify'],
        description: 'The UW RSO Nexus is designed to help new students find projects at the University of Washington. I worked closely with my designers to ensure responsiveness and website quality, worked with backend to ensure smooth logic and communicated effectively with my project manager to ensure deadlines were met.',
        hasLink: false,
        link: 'https://nexusatuw.com/'
    },
    {
        title: 'Healio',
        role: 'Frontend Web Developer',
        image:
            {
                src: healio,
                alt: 'Icon of Healio'
            },
        startDate: 'December 2021',
        endDate: 'May 2022',
        tools: ['React', 'TailwindCSS', 'Firebase', 'Figma'],
        description: 'Healio is a solution for family healthcare management. Healio is a proof of concept that shows how you can easily manage your loved ones\' healthcare information for them. I was the sole frontend developer in a team of four students.',
        hasLink: true,
        link: 'https://healio-archive.web.app/'
    }
];