import "@fontsource/fauna-one";
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';

function App() {
  return (
    <div className='bg-light-tan' itemScope itemType='https://schema.org/WebPage'>
      <Header />
      <div className='pt-[100px]' />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
