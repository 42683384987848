import Intro from '../components/Intro';
import Projects from '../components/Projects';
import About from '../components/About';

function Home() {
  return (
    <div>
      <Intro />
      <h2 className='my-[50px] ml-[5%] font-heading text-4xl' itemProp='headline'>p r o j e c t s</h2>
      <Projects />
      <h2 className='my-[50px] ml-[5%] font-heading text-4xl' itemProp='headline'>a b o u t</h2>
      <About />
      <div className='pb-8' />
    </div>
  );
}

export default Home;
