import NavBar from './NavBar';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const SOCIALS = [
    {
        source: 'email',
        icon: faEnvelope,
        link: 'mailto:jcorille13@gmail.com'
    },
    {
        source: 'linkedin',
        icon: faLinkedin,
        link: 'https://www.linkedin.com/in/jahwoamyy/'
    },
    {
        source: 'github',
        icon: faGithub,
        link: 'https://github.com/jubijubijuu'
    }
];

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const socialsArray = SOCIALS.map((social, index) => {
        let lastSocial = false;
        if (index === SOCIALS.length - 1) {
            lastSocial = true;
        }
        return <Social source={social.source} icon={social.icon} link={social.link} lastSocial={lastSocial} key={index} />;
    });

    return (
        <header itemScope itemType='https://schema.org/WPHeader'>
            <div className='desktop:justify-between flex fixed w-full justify-center h-[5rem] text-white bg-red'>
                <p className='desktop:ml-[5%] tablet:text-2xl font-heading font-bold mt-6 text-xl' itemProp='author'>j e r o m e&nbsp; o r i l l e</p>
                <nav className='desktop:flex mr-[5%] hidden'>
                    {socialsArray}
                </nav>
                <FontAwesomeIcon className={'desktop:hidden tablet:text-4xl animate-popup absolute right-[5%] mt-6 hover:cursor-pointer text-3xl' + (isMenuOpen ? '' : ' hidden')} icon={faX} onClick={() => setMenuOpen(false)} />
                <FontAwesomeIcon className={'desktop:hidden tablet:text-4xl animate-popup absolute right-[5%] mt-6 hover:cursor-pointer text-3xl' + (isMenuOpen ? ' hidden' : '')} icon={faBars} onClick={() => setMenuOpen(true)} />
            </div>
            {isMenuOpen ?
                <NavBar socialsArray={socialsArray} /> :
                null
            }
        </header>
    );
}

function Social(props) {
    const { source, icon, link, lastSocial } = props;
    let dotThing = ' ⸱ ';
    let lastSocialheight = '';
    if (lastSocial) {
        dotThing = '';
        lastSocialheight = ' h-[3.5rem]'; // gives a bit extra spacing on the bottom
    }
    return (
        <a className={'flex mt-6' + lastSocialheight} itemProp='url' href={link} target='_blank' rel='noopener noreferrer'>
            <p className='tablet:text-2xl ml-2 text-xl'>{source}</p>
            <FontAwesomeIcon className='tablet:text-4xl ml-2 text-3xl' icon={icon} />
            <p className='tablet:text-2xl ml-2 text-xl'>{dotThing}</p>
        </a>
    );
}

export default Header;
