import { useState } from 'react';
import chloepic from '../img/chloepic.png';
import college from '../img/college.jpeg';

function About() {
    const [isCareerSelected, setIsCareerSelected] = useState(true);
    let careerStyle = 'hover:border-black hover:bg-green hover:text-white transition px-3 py-1 border-4';
    let factsStyle = careerStyle;
    let headerText = '';

    if (isCareerSelected) {
        careerStyle += ' border-black bg-green text-white';
        factsStyle += ' border-green';
        headerText = 'Why build websites?';
    } else {
        careerStyle += ' border-green';
        factsStyle += ' border-black bg-green text-white';
        headerText = 'Facts about me';
    }

    return (
        <section className='w-[90%] mx-auto pt-8 flex flex-col items-center gap-4 bg-tan border-4 border-brown border-dashed' itemScope itemType='https://schema.org/Statement'>
            <div className='mx-8 flex flex-wrap gap-8 self-start'>
                <button className={careerStyle} onClick={() => setIsCareerSelected(true)}>m y&nbsp; c a r e e r</button>
                <button className={factsStyle} onClick={() => setIsCareerSelected(false)}>s o m e&nbsp; f a c t s</button>
            </div>
            <div className='desktop:justify-between desktop:w-[90%] desktop:flex self-start mx-8 mt-4'>
                <div>
                    <h3 className='font-heading text-3xl' itemProp='headline'>{headerText}</h3>
                    {isCareerSelected ?
                        <div className='desktop:w-[95%] animate-popup mt-4 text-xl' itemProp='about'>
                            <div className=''>
                                <p className=''>During my university years, I was always indecisive in terms of picking a career path: design or programming. I discovered web development in my senior year and loved it because it was the best of both worlds.</p>
                                <p className='mt-4 mb-8'>I loved design because creating functional interfaces for others is euphoric. I loved programming because building things is really fun. My love for both subjects kickstarted my passion as a web developer. I hope to spread my enthusiasm to those who need websites created.</p>
                            </div>
                        </div> :
                        <ul className='desktop:w-[95%] animate-popup mt-4 text-xl' itemProp='about'>
                            <li>- I have two first names, Jerome is one of them</li>
                            <li>- I graduated with two degrees from the University of Washington</li>
                            <ul className='ml-4'>
                                <li>- B.S. in Informatics</li>
                                <li>- B.A. in Geography</li>
                            </ul>
                            <li>- My favorite comfort recipe is bacon carbonara pasta</li>
                            <li>- I have two dogs, one named Rocky and the other named Zekrom</li>
                            <li className='mb-8'>- Currently I spend a lot of my free time playing Lost Ark and raiding with friends on FFXIV</li>
                        </ul>
                    }
                </div>
                <div className='desktop:block hidden mb-8 mr-8'>
                    {isCareerSelected ?
                        <img className='animate-popup max-w-[350px] rounded border-4 rounded-full border-green shadow-box' src={chloepic} alt='Jerome underneath the sakura trees at the University of Washington' /> :
                        <img className='animate-popup max-w-[350px] rounded border-4 rounded-full border-green shadow-box' src={college} alt='Jerome sitting with the Drumheller Fountain behind him' />
                    }
                </div>
            </div>
        </section>
    );
}

export default About;
