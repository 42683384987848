function NavBar(props) {
    const { socialsArray } = props;
    
    return (
        <nav className='desktop:hidden animate-popup fixed mt-[5rem] w-full flex flex-wrap justify-center bg-tan border-dashed border-x-4 border-b-4 border-brown shadow-box' itemScope itemType="https://schema.org/Person">
            {socialsArray}
        </nav>
    );
}

export default NavBar;
