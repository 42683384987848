import { PROJECT_DETAILS } from '../constants';

function Projects() {
    const projectsArray = PROJECT_DETAILS.map((project, index) => {
        const isFirst = (index === 0 ? true : false);
        const projStartDate = project.startDate;
        const projEndDate = project.endDate;
        const timeline = (
            <time>{projStartDate} - {projEndDate}</time>);
        return <ProjectInformation
            title={project.title}
            image={project.image}
            timeline={timeline}
            role={project.role}
            tools={project.tools}
            description={project.description}
            hasLink={project.hasLink}
            link={project.link}
            isFirst={isFirst}
            key={index}
        />;
    })
    return (
        <section className='desktop:gap-y-10 desktop:gap-x-0 desktop:mr-[5%] flex flex-wrap gap-10' itemScope itemType='https://schema.org/CreativeWorkSeries'>
            {projectsArray}
        </section>
    );
}

function ProjectInformation(props) {
    const { title, image, timeline, role, tools, description, hasLink, link, isFirst } = props;

    const toolsArray = tools.map((tool, index) => {
        return <p className='border-black bg-green text-white px-3 py-1 border-4' itemProp='keywords' key={index}>{tool}</p>;
    })

    return (
        <article className='desktop:ml-[5%] desktop:mr-0 desktop:w-[600px] desktop:flex-col tablet:flex-row w-[90%] mx-auto pt-8 flex flex-col items-center gap-4 bg-tan border-4 border-brown border-dashed' itemProp='subjectOf' itemScope itemType='https://schema.org/CreativeWork'>
            <img className='desktop:ml-0 desktop:w-[350px] tablet:ml-8 w-[300px] rounded border-4 rounded-full border-green shadow-box' itemProp='image' src={image.src} alt={image.alt} />
            <div className='desktop:w-[90%] tablet:w-[50%] self-start mx-8'>
                <h3 className='font-heading text-3xl' itemProp='name'>{title}</h3>
                <h4 className='font-heading text-xl italic mt-4' itemProp='temporal'>{timeline}</h4>
                <h4 className='font-heading text-xl italic mt-4'>Role: <span itemProp='keywords'>{role}</span></h4>
                <h4 className='font-heading text-xl italic mt-4'>Tools/Frameworks Used:</h4>
                <div className='flex flex-wrap mt-4 mb-8 gap-4'>
                    {toolsArray}
                </div>
                <p className='mt-4 text-xl mb-8' itemProp='about'>{description}</p>
                {hasLink ?
                    <a itemProp='url' href={link} target='_blank' rel='noopener noreferrer'>
                        <button className='hover:border-black hover:bg-green hover:text-white transition mb-8 px-3 py-1 border-4 border-green'>v i s i t&nbsp; s i t e</button>
                    </a> :
                    null}
            </div>
        </article>
    );
}

export default Projects;
