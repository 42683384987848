import chloepic from '../img/chloepic.png';

function Intro() {
    return (
        <section className='tablet:justify-between tablet:flex-row w-[90%] mx-auto pt-8 flex flex-col items-center gap-4 bg-tan border-4 border-brown border-dashed' itemScope itemType='https://schema.org/Person'>
            <img className='tablet:hidden w-[300px] rounded border-4 rounded-full border-green shadow-box' itemProp='image' src={chloepic} alt='Jerome underneath the sakura trees at the University of Washington' />
            <div className='desktop:w-full tablet:w-[50%] self-start mx-8'>
                <h1 className='font-heading text-3xl'>Hello, my name is <span itemProp='name'>Jerome Orille</span>.</h1>
                <p className='mt-4 text-xl mb-8'>I am based in <strong itemProp='workLocation'>Washington</strong>. I am a <strong itemProp='jobTitle'>web developer</strong>. Currently, I am seeking a <strong itemProp='seeks'>frontend web developer position</strong>. I can code with <strong itemProp='knowsLanguage'>React</strong> and use <strong itemProp='knowsLanguage'>WordPress</strong> as a CMS.</p>
            </div>
            <div className='desktop:hidden tablet:flex mr-8 flex-wrap hidden gap-10 mb-8'>
                <img className='w-[350px] rounded border-4 rounded-full border-green shadow-box' itemProp='image' src={chloepic} alt='Jerome underneath the sakura trees at the University of Washington'/>
            </div>
        </section>
    );
}

export default Intro;
